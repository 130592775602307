
import Vue, { type PropType } from "vue"
import { type EToggleSwitchOption, formField } from "@evercam/ui"

export default Vue.extend({
  name: "MultiOptionsField",
  mixins: [formField],
  props: {
    options: {
      type: Array as PropType<EToggleSwitchOption[]>,
      required: true,
    },
  },
})


import Vue from "vue"
import CtkDateTimePicker from "@evercam/shared/components/CtkDateTimePicker.vue"
import { formField } from "@evercam/ui"

export default Vue.extend({
  name: "DateTimeField",
  components: {
    CtkDateTimePicker,
  },
  mixins: [formField],
})


import Highcharts, { type OptionsOperatorValue } from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsGantt from "highcharts/modules/gantt"
import HighchartsExporting from "highcharts/modules/exporting"
import Vue, { type PropType } from "vue"

export default Vue.extend({
  name: "CopilotChart",
  props: {
    options: {
      type: Object as PropType<Highcharts.Options>,
      required: true,
    },
    redraw: {
      type: Boolean,
      default: true,
    },
    oneToOneUpdate: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      chart: null as Highcharts.Chart | null,
    }
  },
  computed: {
    containerClasses() {
      return {
        "e-border-b-gray-300": !this.$vuetify.theme.isDark,
        "e-border-b-gray-600": this.$vuetify.theme.isDark,
        "e-bg-gray-900 e-text-gray-200 e-border-gray-700":
          this.$vuetify.theme.isDark,
        "e-text-gray-500 e-bg-white e-border-gray-300":
          !this.$vuetify.theme.isDark,
      }
    },
  },
  watch: {
    options: {
      handler(newValue) {
        this.updateChart(newValue)
      },
      deep: true,
    },
  },
  created() {
    this.setupHighchartsModules()
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  methods: {
    setupHighchartsModules() {
      // Initialize Highcharts modules
      HighchartsMore(Highcharts)
      HighchartsGantt(Highcharts)
      HighchartsExporting(Highcharts)

      // Plug-in to render plot bands for the weekends
      Highcharts.addEvent(Highcharts.Axis, "foundExtremes", (e) => {
        if (!e?.target?.options?.custom?.weekendPlotBands) {
          return
        }

        const axis = e.target,
          chart = axis.chart,
          ONE_DAY_IN_MILLISENCONDS = 24 * 36e5,
          isWeekend = (t: string | number) =>
            /[06]/.test(chart.time.dateFormat("%w", t)),
          plotBands: Record<string, unknown>[] = []

        let isCurrentlyWeekend = false

        for (
          let currentTime =
            Math.floor(axis.min / ONE_DAY_IN_MILLISENCONDS) *
            ONE_DAY_IN_MILLISENCONDS;
          currentTime <=
          Math.ceil(axis.max / ONE_DAY_IN_MILLISENCONDS) *
            ONE_DAY_IN_MILLISENCONDS;
          currentTime += ONE_DAY_IN_MILLISENCONDS
        ) {
          const lastPlotBand = plotBands.at(-1)
          if (isWeekend(currentTime) && !isCurrentlyWeekend) {
            plotBands.push({
              from: currentTime,
              color: {
                pattern: {
                  path: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
                  width: 10,
                  height: 10,
                  color: "rgba(128,128,128,0.15)",
                },
              },
            })
            isCurrentlyWeekend = true
          }

          if (!isWeekend(currentTime) && isCurrentlyWeekend && lastPlotBand) {
            lastPlotBand.to = currentTime
            isCurrentlyWeekend = false
          }
        }
        axis.options.plotBands = plotBands
      })
    },
    initChart() {
      if (this.chart) {
        this.chart.destroy()
      }
      const copilotChart = this.$refs.copilotChart as HTMLElement
      const options: Highcharts.Options = {
        ...this.options,
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: [
              {
                enabled: true,
              },
              {
                enabled: true,
                filter: {
                  operator: ">" as OptionsOperatorValue,
                  property: "percentage",
                  value: 10,
                },
              },
            ],
          },
        },
      }
      this.chart =
        this.options.chart?.type === "gantt"
          ? Highcharts.ganttChart(copilotChart, this.options)
          : Highcharts.chart(copilotChart, options)
    },
    updateChart(newOptions: Highcharts.Options) {
      if (!this.chart) {
        this.initChart()

        return
      }
      // Update series data if it exists
      if (newOptions.series) {
        newOptions.series.forEach((series, index) => {
          if (this.chart?.series[index]) {
            this.chart.series[index].setData(
              series.data,
              this.redraw,
              this.oneToOneUpdate
            )
          }
        })
      }
      // Update other options
      this.chart.update(newOptions, this.redraw, this.oneToOneUpdate)
    },
  },
})
